import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import Typo from '@material-ui/core/Typography';
import Layout from '../components/layout';
import DefaultImage from '../images/hclogo.png';
import CustomImage from '../components/customImage';
import HomeGrid from '../components/HomeGrid';
import ROUTER from '../constants/router';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: spacing(0),
    width: '100%',
    height: '100vh',
  },
  banner: {
    width: spacing(20),
  },
  link: {
    padding: spacing(0, 4),
    textAlign: 'center',
  },
}));

const NotFoundPage = () => {
  const s = useStyles();

  return (
    <Layout>
      <HomeGrid fluid justify="center" alignItems="center" className={s.root}>
        <CustomImage src={DefaultImage} alt="404" className={s.banner} />
        <HomeGrid className={s.link}>
          <Typo>Trang cần tìm chưa tồn tại. Về&nbsp;</Typo>
          <Link to={ROUTER.CUSTOMER.ROOT}>
            <Typo>trang chủ</Typo>
          </Link>
        </HomeGrid>
      </HomeGrid>
    </Layout>
  );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
